<template>
  <div>
    <el-table
      v-loading="loading"
      size="mini"
      border
      empty-text="Нет преемников"
      :cell-class-name="getCellClass"
      :data="successors"
      :row-key="(row) => row.id"
      @sort-change="sortChanged($event, loadSuccessors)"
    >
      <el-table-column label="Уровень готовности преемника" prop="ready_level" sortable="custom">
        <template v-slot="scope">
          <click-to-edit
            v-model="scope.row.ready_level"
            placeholder="Выберите уровень"
            :disabled="!$canAndRulesPass(authUser, 'successors.update')"
            type="select"
            select-filterable
            :select-options="Object.entries($companyConfiguration.settings.getEnum('successor_ready_levels')).map((e) => ( {id: e[0], name: e[1]}))"
            select-option-id="id"
            select-option-name="name"
            select-cast-value-to-int
            @input="updateSuccessor(scope.row)"
          >
          </click-to-edit>
        </template>
      </el-table-column>
      <el-table-column label="Внешний преемник" width="250">
        <template #header>
          <div style="display: flex; justify-content: space-between">
            <div>Внешний преемник</div>
            <div>
              <el-popover
                placement="top"
                width="400"
                trigger="click"
              >
                <el-button
                  slot="reference"
                  type="text"
                  round
                  size="mini"
                  icon="fas fa-info-circle"
                ></el-button>
                <div style="white-space: pre-line">Выставляется при наличии преемника вне СМЗ</div>
              </el-popover>
            </div>
          </div>
        </template>
        <template v-slot="scope">
          <el-checkbox
            v-model="scope.row.is_external"
            :disabled="!$canAndRulesPass(authUser, 'successors.update')"
            @change="updateSuccessor(scope.row)"
          >
          </el-checkbox>
        </template>
      </el-table-column>

      <el-table-column label="ФИО преемника">
        <template v-slot="scope">
          <div v-if="!scope.row.is_external">
            <div style="display: flex; justify-content: space-between">
              <div>{{scope.row.successorUser ? scope.row.successorUser.full_name : ''}}</div>
              <div v-if="$canAndRulesPass(authUser, 'successors.update')">
                <add-users-dialog
                  single-select
                  skip-permissions
                  title-text="Выбор преемника"
                  add-button-text="Нажмите на строку для выбора"
                  @users-selected="newSuccessorUserSelected(scope.row, $event)"
                >
                  <el-button
                    slot="reference"
                    size="mini"
                    icon="fas fa-person-booth"
                  >
                  </el-button>
                </add-users-dialog>
              </div>
            </div>
          </div>
          <div v-if="scope.row.is_external">
            <click-to-edit
              v-model="scope.row.full_name"
              :disabled="!$canAndRulesPass(authUser, 'successors.update')"
              placeholder="Введите ФИО"
              :word-limit="255"
              show-word-limit
              @input="updateSuccessor(scope.row)"
            ></click-to-edit>
          </div>
        </template>
      </el-table-column>

      <el-table-column label="Возраст" width="100">
        <template v-slot="scope">
          <div v-if="!scope.row.is_external">{{scope.row.successorUser ? scope.row.successorUser.age : null}}</div>
          <div v-if="scope.row.is_external">
            <click-to-edit
              v-model="scope.row.age"
              :disabled="!$canAndRulesPass(authUser, 'successors.update')"
              placeholder="Введите возраст"
              digits-only
              @input="updateSuccessor(scope.row)"
            ></click-to-edit>
          </div>
        </template>
      </el-table-column>

      <el-table-column label="Должность">
        <template v-slot="scope">
          <div v-if="!scope.row.is_external">{{scope.row.successorUser ? scope.row.successorUser.position : null}}</div>
          <div v-if="scope.row.is_external">
            <click-to-edit
              v-model="scope.row.position"
              :disabled="!$canAndRulesPass(authUser, 'successors.update')"
              placeholder="Введите должность"
              :word-limit="255"
              show-word-limit
              @input="updateSuccessor(scope.row)"
            ></click-to-edit>
          </div>
        </template>
      </el-table-column>

      <el-table-column label="Бенд" width="80" prop="user.band" sortable="custom">
        <template v-slot="scope">
          <div v-if="!scope.row.is_external">{{scope.row.successorUser ? scope.row.successorUser.band : null}}</div>
        </template>
      </el-table-column>

      <el-table-column label="Риск увольнения" width="130">
        <template v-slot="scope">
          <div v-if="!scope.row.is_external && scope.row.successorUser && scope.row.successorUser.currentCard && scope.row.successorUser.currentCard.info">
            {{ evaluations[scope.row.successorUser.currentCard.info.evaluate_risk_dismissal_id] }}
          </div>
        </template>
      </el-table-column>

      <el-table-column label="Комментарий">
        <template v-slot="scope">
            <click-to-edit
              v-model="scope.row.comment"
              :disabled="!$canAndRulesPass(authUser, 'successors.update')"
              placeholder=""
              type="textarea"
              @input="updateSuccessor(scope.row)"
            ></click-to-edit>
        </template>
      </el-table-column>
      <el-table-column label="" width="50" v-if="$canAndRulesPass(authUser, 'successors.delete')">
        <template v-slot="scope">
          <el-popconfirm
            confirm-button-text='Да'
            cancel-button-text='нет'
            icon="el-icon-question"
            title="Вы уверены, что хотите удалить преемника?"
            @confirm="deleteSuccessor(scope.row)"
          >
            <el-button
              slot="reference"
              class="table-action-button"
              round
              icon="far fa-trash-alt"
            ></el-button>
          </el-popconfirm>
        </template>
      </el-table-column>
    </el-table>

    <el-button
      v-if="$canAndRulesPass(authUser, 'successors.create')"
      style="margin-top: 10px"
      size="mini"
      icon="fas fa-plus"
      @click="addSuccessor"
    >
      Добавить преемника
    </el-button>
  </div>
</template>

<script>

import requestSender from "@/api/base/requestSender";
import ClickToEdit from "@/components/ClickToEdit.vue";
import UserSelect from "@/components/filters/users/UserSelect.vue";
import AddUsersDialog from "@/components/user/AddUsersDialog.vue";
import {mapGetters} from "vuex";
import {sorter} from "@/mixins/sorter";


export default {
  name: "successors-table",
  mixins:[sorter],
  components: {AddUsersDialog, UserSelect, ClickToEdit},
  props: {
    user: {type: Object, required: true},
  },

  mounted() {
    this.loadSuccessors();
  },
  computed: {
    ...mapGetters({
      authUser: 'user',
    }),
  },
  data() {
    return {
      loading: false,

      evaluations: {},
      successors: [],
    }
  },

  methods: {
    getCellClass({row, column, rowIndex, columnIndex}){
      if( column.label === 'Риск увольнения' ){
        if( row.is_external ){
          return null;
        }
        if( !row.successorUser ){
          return null;
        }
        switch (row.successorUser.currentCard.info.evaluate_risk_dismissal_id){
          case 18:
            return 'successors-table-risk-cell-low';
          case 17:
            return 'successors-table-risk-cell-normal';
          case 16:
            return 'successors-table-risk-cell-high';
        }
        return '';
      }
      return null;
    },
    loadSuccessors(){
      this.loading = true;

      requestSender('get', 'successor/get-successors-for-user', {
        user_id: this.user.id,
        expand: 'successorUser.currentCard.info',

        [this.sorter.param]: this.sorter.sort,
      })
        .then(({data, paginator, sorter}) => {
          this.evaluations = data.evaluations;
          this.successors = data.successors;
          this.sorterFill(sorter);
        })
        .finally(() => {
          this.loading = false;
        });
    },

    addSuccessor(){
      this.loading = true;

      requestSender('post', 'successor/save-successor', {
        user_id: this.user.id,
      })
        .then(data => {
          this.loadSuccessors();
        })
    },

    updateSuccessor(successor){
      this.loading = true;

      requestSender('post', 'successor/save-successor', {
        id: successor.id,
        user_id: this.user.id,
        ready_level: successor.ready_level,
        is_external: successor.is_external,
        successor_user_id: successor.successor_user_id,
        full_name: successor.full_name,
        age: successor.age,
        position: successor.position,
        comment: successor.comment,
      })
        .then(data => {
          this.loadSuccessors();
          this.$emit('new-user-position-risk', data.user_position_risk);
        })
    },

    newSuccessorUserSelected(row, newUser){
      row.successor_user_id = newUser.id;
      this.updateSuccessor(row);
    },

    deleteSuccessor(successor){
      this.loading = true;

      requestSender('post', 'successor/delete-successor', {
        id: successor.id,
      })
        .then(data => {
          this.loadSuccessors();
          this.$emit('new-user-position-risk', data.user_position_risk);
        })
    }
  },
}
</script>

<style lang="scss">

</style>